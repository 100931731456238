export default [
  {
    path: '/invoice/:id',
    name: 'pay-invoice',
    component: () => import('@/views/pages/pay/Invoice.vue'),
    meta: {
      pageTitle: 'Uw factuur',
    },
  },
  {
    path: '/',
    name: 'home-redirect',
    beforeEnter() {
      window.location.href = 'https://my.payrite.nl'
    },
  },
]
